@media (max-width: 1215px) {
  .ads-search { width: 260px !important; }
  .ps-btn-left { margin-left: 0 !important;}
  .ps-butn { width: 143px !important;}
  .ps-butn:before { left: 2px !important;}
  .ps-butn-1 { width: 123px !important;}
  .cbe-btn { width: 155px !important;}
  .search-container .user-sbar-ip { width: 100% !important;}
  .orgNameBtn { width: 184px !important;}
  .collection-nameRight .ads-search { margin-right: 0px !important;  margin-left: auto;  margin-right: 20px !important; }  /* .date-added:before { right: 46px !important;} */
  .collection-nameRight .search-icon {position: absolute; left: 70px !important; }
  .adu-user-button {
    width: 115px !important;
}
.admin-user-search .row{
  justify-content: center !important;
}
}

@media (max-width: 1100px) {
  .collection-nameRight .search-icon {  left: 90px !important;   }
}



@media (max-width: 1190px) {
  .progress-value {     right: 108px;}
}


@media (max-width: 1100px) {
  .progress-value { right: 97px;}
  .ads-search { width: 240px !important;}
  .ps-btn-left { margin-left: 20px; }
  .anchor-margin a { margin-right: 10px;}
  .ads-title h1 { font-size: 20px;}
  p.date-up.date-added { padding-right: 70px;}
  .collection-nameRight .ads-search 
  .collection-nameRight .search-icon {  position: absolute;  left: 90px !important;}
}

@media (max-width: 1024px) {

  .searchbar-padding { padding: 6px 0 6px 40px !important;  }
  .option-img img { width: 10%;     right: 11px;}
  td.file-size.toptwo {  padding-left: 39px; }
  td.date {  text-align: right; }
  .progress-value { right: 85px;}
  .ads-search { width: 240px !important;}
  .ps-btn-left { margin-left: 20px; }
  .anchor-margin a { margin-right: 10px;}
  .ads-title h1 { font-size: 20px;}
  .permission p { padding-left: 58px !important;}
  p.date-up.date-added {  text-align: center;  padding-right: 65px; }
  .cnDocs-padding {  padding-left: 86px; }
  .cnUser-padding { padding-left: 46px !important; }
  }


@media (max-width: 991px) {
  .top-org { margin-bottom: 0 !important;}
	.user-icon { width: 100%; text-align: center;}
  .collectioName {  padding: 20px 0 !important;}
  .user-content { text-align: center;}
  .inner-sent-invite .col-lg-4 { flex: 0 0 auto; width: 60%;}
  .left-container { width: 30%;}
  .chat-right { width: 70%;}
  .main-column { padding-top: 13px; }
  .nc-top { padding: 10px;}
  .input-chat-mic {  padding: 10px !important;}
  .input-chat-mic a img { margin-top: 10px; width: 100%;}
  input.form-control.mic-text-input { padding: 6px;}
  textarea.form-control.mic-text-input { padding: 6px;}
  .progress-value { right: 75px;}
  .date {  padding-left: 68px !important; }
  span.progress.add-docs-progressbar { width: 48%; }
  span.progress-value.knb-pv { right: 34px;}
  span.progress.add-docs-progressbar-1 { width: 70%;  height: 8px;  margin: 0 auto;  }
  .permission p { padding-left: 47px !important; width: 50%;}
  p.date-up.date-added { text-align: left; padding-right: 0; }
  .cnDocs-padding {  padding-left: 80px; }
  .cnUser-padding { padding-left: 42px !important; }
  .collection-nameRight .search-icon { left: 77px !important;}
  .adu-body { padding: 10px !important;}
  
}
@media (max-width: 970px) {
  .collection-nameRight .search-icon { left: 70px !important;}

}
@media (max-width: 960px) {
  .collection-nameRight .search-icon { left: 66px !important;}

}
@media (max-width: 940px) {
  .collection-nameRight .search-icon { left: 56px !important;}

}
@media (max-width: 920px) {
  .collection-nameRight .search-icon { left: 46px !important;}

}
 @media (max-width: 900px) {
  .iuc-bg:before { left: -40px; width: 100%; }
  .verification-code { width: 60%; }
  .logo-voice:before {  width: 220px;  }
  .inner-container { width: 90%; }
  .ads-title { width: 50%; font-size: 26px !important;}
  .ads-title h1{font-size: 26px !important;}
  .addDocssearch { width: 50% !important;}
  .ads-search { width: 265px !important;}
  .anchor-margin { width: 100%; justify-content: space-around !important; margin-top: 20px;}
  .document.users {   width: 30%;}
  .permission {    width: 15%; }
  .date-padding {  width: 18%; }
  .userDates p { width: 100% !important; }
  .cnDocs-padding {  padding-left: 74px; }
  .cnUser-padding { padding-left: 40px !important; }
  .collection-nameRight .search-icon { left: 15px !important;}

}
@media (max-width: 890px) { 
  .orgNameBtn { width: 200px !important;}
  .orgNameBtn { font-size: 15px !important;}
 }

@media (max-width: 865px) {
  .progress-value { right: 65px;}
  .edit-deleteHover {width: 42% !important;}
  .date-padding {    width: 21%; }
  .permission { width: 12%;}
  .cnDocs-padding {  padding-left: 70px; }
  .cnUser-padding { padding-left: 40px !important; }
  .collection-nameRight .search-icon { left: 6px !important;}
  .collection-nameLeft { width: 100% !important;}
  .collection-nameRight { width: 100% !important;}
  .collection-nameRight { margin-right: 5px !important;}
  a.orgNameBtn:before{ height: 22px !important; width: 22px; }
  .collection-nameRight .search-icon { left: 64px !important;}
  .orgNameBtn { width: 150px !important;}
  .adu-right-rad p {  font-size: 17px;    padding-left: 13px; }

}

@media (max-width: 768px) {

  .logo-voice { height: 200px;}
  .logo-voice:before {top: 45%; left: 50%; transform: translate(-50%, -50%); width: 180px; height: 60px; }
  .pt-13 { padding-top: 20px; }
  .pb-20 { padding-bottom: 20px; }
  .signup form { margin-bottom: 0; }
  .signup form { margin-bottom: 0;   }
  .side-bg::before { display: none;}
  .btn-invite-Continue { width: 40%; margin: 0 auto;}
  .add-docs-progressbar { width: 70% !important;}
  .iuc-bg:before { background-position: inherit;}
  .nc-top { padding: 10px; width: 90%; align-items: center;}
  .input-chat-mic { width: 90%;}
  .option-img img {     top: -28px; right: 18px; }
  a.new_chat { padding: 6px;}
  .logo img { max-width: 200px;}
  form.chat-list-left input::placeholder {   font-size: 13px; }
  ul.top-org li a { font-size: 14px;}
  .chat-top-bar img:first-child { max-width: 200px;}
  .user-profile-pic { margin-left: 10px;}
  .chat-right { width: 100%;}
  .top-toggle {display: inline-block;}
  .toggle-button { display: inline-block;}
  .left-container {width: 40%; margin: 0 !important; border-radius: 0; position: absolute; left: 0; top: 139px;}
  .left-0{ left: 0; display:block;}
  td.file-size.toptwo {padding-left: 31px;}
  .progress-value { right: 4px;}
  .logo-voice {    height: 100vh; width: 33.33333333% !important; }
  .cc-right { width: 66.6666%;  }
  span.progress-value.knb-pv { right: 24px;}
  .ads-title h1 { font-size: 20px !important;}
  .date-padding { width: 22%; } 
  .orgRight-row { max-width: 1260px !important; margin: 0 !important;}
  .qcdu {  width: 80% !important;   padding-left: 84px; }
  .orgRow-left { width: 100%;}
  .orgRight-row { margin-bottom: 10px !important;}
  .right-verify-code { width: 66.66% !important; }
  .verification-code { width: 80% !important;}
  .welcome-right { width: 66.66%; }
  .cnDocs-padding {  padding-left: 60px; }
  .cnUser-padding { padding-left: 40px !important; }
  .radio-main {   padding-left: 70px !important; }
  .radio-main {    justify-content: center; padding-left: 0 !important;}
  .adu-align { text-align: center !important; padding: 10px 0;}
  .adu-email { text-align: center !important; padding: 0 0 10px 0;}
  .adu-user-delete {    justify-content: space-evenly; }



  /* toggle chat org */
  .top-org { display: none !important;}
  .logo-toggle { display: flex; width: 100%; justify-content: center;}
  .toggle-icon svg { color: #fff;  height: 44px;  width: 44px; }
  .col-lg-4.toggleChat {  position: absolute;    left: -350px; display: none;   top: -20px; }
  .show-toggle { left: 0; display: block;}
  .fz-width{width: 73%;}
  .edit-deleteHover {width: 18% !important;}
}
@media (max-width: 720px) {
  .date-padding {   width: 26%; }
  .orgRow-left {  width: 100%; }
  .orgRow-right {    width: 60%; }
  .action-btn { width: 20%;}
  .right-text { width: 80%; padding-left: 98px; }
  .cnDocs-padding {  padding-left: 54px; }
  .cnUser-padding { padding-left: 40px !important; }

}
@media (max-width: 597px) {
  .left-container { top: 166px; width: 50%;}
  .mySection { font-size: 16px;}
  table.add-documents tr td.folder-img { font-size: 15px;}
  span.progress-value.knb-pv { right: 12px;}
  .date-added:before { right: 30px;}
  .cnDocs-padding {  padding-left: 0; }

}

@media (max-width: 576px) {
  .size {   padding-left: 0px;  }
  .type {    text-align: left;    padding-left: 0;  }
  .ad-heading .document {    padding-left: 0;}
  .logo-voice:before { width: 140px;}
  .signup-right { width: 66.6666%;}
  .form-control { padding: 8px 15px;}
  .btn-voice-submit { padding: 13px !important;}
  .date-up:before { display: none;}
  .ads-search{   padding: 8px 32px !important;  width: 245px !important;}
  .cDU { justify-content: start !important;}
  .fz-width{width: 80%;}
  .edit-deleteHover {width: 20% !important;}
  .user-title { margin: 0 auto;  width: auto; }
  .user-search { margin:  0 auto;}
  .permission p { padding-left: 0 !important;}
  .permission { width: 0 !important;}
  .add-org-heading {  margin-bottom: 0 !important;   }
  .add-org-btn { margin: 0 auto !important; }
  .org-heading h1 { text-align: center;  margin-bottom: 10px;}
  .orgRow-left {  width: 100%; margin-bottom: 10px; }
  .orgRow-right {    width: 100%; margin-bottom: 10px;}
  .action-btn { width: 100%; text-align: left !important; padding-left: 95px;}
  .right-text { width: 100%; padding-left: 98px; }
  .action-btn { width: 100%; padding-left: 100px !important;}
  .right-verify-code { width: 66.66% !important; }
  .verification-code { width: 80% !important;}
  .cn-Padding { padding-left: 0 !important;}
  .cnUser-padding { padding-left: 0 !important;}
  .adu-user-delete {  justify-content: center; }
  .adu-user-delete { margin: 10px 0; padding-left: 50px !important;}
  .adu-user-delete p {  margin-right: 25px; }
  .adu-email {  padding: 0 0 10px 69px;   text-align: left !important; }
  .radio-main-1 { padding: 0 0 10px 84px; }
  .adus-box-seach { margin: 0 auto !important;}
  .adu-align { text-align: left !important;}
  .adus-box-main {padding-left: 45px;    margin-bottom: 10px;}
  .radio-main {    justify-content: left !important;    padding-left: 20px !important; margin-bottom: 8px !important;}
  .adu-right-rad { text-align: left !important;    padding-left: 55px; }
  .profile2 nav {  height: 30vh !important;  border-bottom: 1px solid #C1CCD6;  padding-top: 0px; }
  .adu-rsp-mail {padding-left: 0 !important; text-align: center !important;}
  .radio-rsp-main { padding-left: 0 !important; text-align: center !important; }

}

@media (max-width: 500px) {
  .email { width: 100%; margin-bottom: 10px;}
  form .formcollection-inner { justify-content: space-evenly;}
  .formcollection-inner input[type=email] { max-width: 100% !important;}
  .btn-invite { width: 100%;}
  .inner-sent-invite .col-lg-4 { flex: 0 0 auto; width: 100%;}
  .add-docs-heading th { font-size: 15px;}
  table.add-documents tr td.folder-img { font-size: 16px;}
  td.pdf {  font-size: 14px; }
  .add-docs-progressbar { width: 100%; }
  .add-document .heading h1 { margin-bottom: 20px;}
  .col-lg-6.heading.text-start { display: contents; margin: 0 auto; }
  .col-lg-6.add-new-folder.text-end { text-align: center !important; margin-bottom: 20px;}
  .userLater a { padding: 8px 9px;}
  .table tr { display: flex;  flex-wrap: wrap; padding: 10px 0;}
  .add-docs-tr td:last-child { width: 100%; }
  table.add-documents tr td.folder-img { width: 100%;}
  td.pdf { width: 100%; text-align: left; padding-left: 27px; font-size: 21px; }
  td.file-size { padding-left: 27px; font-size: 21px; width: 100%;}
  td.file-size.dates {   padding-left: 27px !important; }
  .pdf::before {     left: 24px; top: 8px;}
  .iuc-bg:before { background-position: unset;}
  .userLater p{ width: 100%;}
  td.mySection { width: 100%;}
  .chat-top-bar img:first-child { max-width: 135px;}
  .top-org li:not(:last-child)::after { display: none;}
  .main-column { justify-content: space-between; flex-wrap: wrap;  justify-content: center;  text-align: center; }
  .logo {  display: block !important;   width: 100% !important; }
  .top-org { padding-right: 0; justify-content: center;}
  .top-org li:not(:last-child)::after { display: block;}
  ul.top-org li a { font-size: 16px;}
  ul.top-org li:first-child img { margin-left: -20px; }
  .left-container { top: 211px;}
  .add-docs-progressbar {   width: 90% !important; }
  .add-new-folder { padding: 0;}
  .add-docs-tr td:last-child { padding: 0 20px; }
  .logo-voice {   height: 80px;    width: 100% !important; background-image: none;}
  .logo-voice:before {   width: 200px; }
  .uyp-photo, .cc-right, .signup-right {   width: 100%;  }
  .formcollection-inner .email {    width: 100% !important; }
  .collect.d-flex.me-auto {   margin: auto !important; }
  span.progress.add-docs-progressbar { width: 70% !important; }
  .ads-title { width: 100%; font-size: 26px !important; }
  .ads-title h1 { text-align: center !important; font-size: 22px !important;}
  .ads-search { width: 100% !important;}
  .add-new-folder { display: block !important; margin: 0 auto;}
  .fz-width {width: 33%;}
  .ad-heading .document { width: 100%; text-align: left; padding-left: 0 !important;}
  .permission {   width: 100% !important; }
  .orgRow-left {  width: 100% !important; }
  .orgRow-right {    width: 100% !important; }
  .action-btn { width: 100% !important;}
  .userType {    width: 35%;}
  td.userDates {    width: 40% !important;    display: inline; }
  .right-verify-code { width: 90% !important; margin: 0 auto;}
  .welcome-right {  width: 90%;   margin: 0 auto; }



  /* toggle chat */
  .col-lg-4.toggleChat {
    position: absolute;
    left: -350px;
}
}
@media (max-width: 450px){
    .signup form {width: 100%; }
    .btn-invite-Continue { width: 90%;}
    .orgRow-left {  width: 100%; }
    .orgRow-right {    width: 100%; }
    .action-btn { width: 100%;}
    .qcdu {  padding-left: 23px;  }
    .breakItems { flex-wrap: wrap;}
    .action-btn { padding-left: 0 !important; padding-left: 105px !important;}
}

@media(max-width: 400px)
{
  .left-container { width: 100%; z-index: 99;}
  .chat-list input::placeholder { font-size: 18px;}
  .option-img img { top: -33px; }
  input.form-control.mic-text-input { margin-left: 2px; height: 35px;}
  textarea.form-control.mic-text-input { margin-left: 2px; height: 35px;}
  .send-btn { padding-left: 34px; height: 35px;}
  .btn-submit:after { top: 9px;}
  .input-chat-mic a img { margin-top: 4px;}
  .add-docs-progressbar { width: 85% !important; }
  .ads-search { padding: 4px 11px 4px 31px !important; }
  .action-btn { padding-left: 10px !important; display: flex; justify-content: space-evenly; }
  td.userDates { width: 50% !important; padding-right: 0 !important;}
}

@media(max-width: 360px){
  .left-container {   width: 100%;   z-index: 99;   top: 78%; }
.fz-width {width: 45%;}
.userDates p {  width: 100% !important;  text-align: left; padding: 0 10px !important; }
.breakItems { flex-wrap: wrap;}
}

@media (max-width: 320px) {
  .iuc-bg:before { left: -40px; top: -50px;}
  .action-btn { padding-left: 30px !important;}
}


